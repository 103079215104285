import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { useTheme } from "@/hooks/useTheme";
import { Tooltip } from "../Tooltip";

interface IFlashSale {
  title: string;
  subtitle: string;
  tooltip: string;
  onClick: () => void;
}

export const FlashSale: React.FC<IFlashSale & IWithCustomStyle> = ({
  title,
  subtitle,
  tooltip,
  onClick,
  customStyle = {},
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation("common");

  return (
    <div
      className={css({
        backgroundColor: colors.RED,
        borderRadius: 10,
        maxWidth: "65%",
        margin: "0 auto",
        marginTop: 24,
        display: "flex",
        color: colors.WHITE,
        padding: "20px 30px",
        flexDirection: "column",
        [bp.FROM_DESKTOP]: {
          flexDirection: "row",
        },
        ...customStyle,
      })}
    >
      <div
        className={css({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 28,
          marginRight: 12,
        })}
      >
        ⚡️
      </div>
      <div
        className={css({
          flex: "2 auto",
        })}
      >
        <p
          className={css({
            color: colors.WHITE,
            fontSize: 20,
            fontWeight: "bold",
            textAlign: "left",
          })}
        >
          <Trans
            i18nKey={title}
            components={[
              <Tooltip
                key="0"
                text={tooltip}
                textStyles={{
                  textDecoration: "underline",
                  textDecorationStyle: "dashed",
                  textDecorationColor: colors.WHITE,
                  textUnderlineOffset: 3,
                  textDecorationThickness: 1,
                }}
                tooltipStyles={{
                  top: 35,
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "14px 20px",
                  lineHeight: 1.125,
                  backgroundColor: colors.GRADIENT_PURPLE,
                  borderRadius: 6,
                  zIndex: 1,
                  fontWeight: "normal",
                  width: "max-content",
                  maxWidth: 260,
                }}
                tooltipArrowStyles={{
                  width: 16,
                  height: 16,
                  backgroundColor: colors.GRADIENT_PURPLE,
                  borderRadius: 3,
                  transform: "rotate(45deg)",
                  left: "calc(50% - 8px)",
                  top: -6,
                }}
                tooltipTextStyles={{
                  fontSize: 12,
                  letterSpacing: 0.25,
                  color: colors.WHITE,
                }}
              />,
            ]}
          />
        </p>
        <p
          className={css({
            color: colors.WHITE,
            fontSize: 16,
            textAlign: "left",
          })}
        >
          {subtitle}
        </p>
      </div>
      <div
        className={css({
          color: colors.WHITE,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        })}
      >
        <button
          type="button"
          className={css({
            color: colors.WHITE,
            fontWeight: "bold",
            border: "none",
            background: "none",
            display: "flex",
            textDecoration: "underline",
            fontSize: 14,
          })}
          onClick={onClick}
        >
          <svg
            width="26"
            height="21"
            viewBox="0 0 26 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={css({
              marginRight: 20,
              height: 16,
            })}
          >
            <path
              d="M0 10.5H24.5M24.5 10.5L16 2M24.5 10.5L16 19"
              stroke="white"
              strokeWidth="3"
              strokeLinejoin="round"
            />
          </svg>
          {t("flashsale.getIt")}
        </button>
      </div>
    </div>
  );
};
