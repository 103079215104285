export const getFlashSaleEndDate = (
  locale: string | string[],
  options: Intl.DateTimeFormatOptions,
) => {
  const currentTime = new Date();
  const currentDay = currentTime.getDay();
  const currentDate = currentTime.getDate();
  const dayOfWeek = 5;
  currentTime.setDate(currentDate + ((dayOfWeek + 7 - currentDay) % 7));
  return new Date(currentTime).toLocaleDateString(locale, options);
};
