import React from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import {
  PricingCard,
  PricingListElement,
  PricingTooltip,
} from "@/components/PricingCard";
import { PRICING } from "@/constants/pricing";

interface IPayAsYouGoCard {
  fileInfo?: {
    artboardsCount: number;
  };
  cta: {
    title: string;
    onClick: () => void;
  };
}

/**
 * This card can be used both in pricing section and during checkout.
 *
 * For pricing section:
 * - dont pass `fileInfo`
 * - pass `cta` with scroll function
 *
 * For checkout:
 * - pass `fileInfo` which contains artboards number
 * - pass `cta` with checkout handler
 */
export const PayAsYouGoCard: React.FC<IPayAsYouGoCard> = ({
  fileInfo,
  cta,
}) => {
  const { t } = useTranslation("section/pricing");

  return (
    <PricingCard
      header={{
        backgroundType: "LIGHT_BLUE",
        title: t("plans.payAsYouGo.title"),
        subtitle: t("plans.payAsYouGo.subtitle", {
          price: PRICING.PAY_AS_YOU_GO.price,
        }),
      }}
      cta={cta}
    >
      <PricingListElement
        price={{
          value: PRICING.PAY_AS_YOU_GO.price,
          suffix: t("plans.payAsYouGo.file"),
        }}
        disabled={
          fileInfo &&
          fileInfo.artboardsCount > PRICING.SMALL_FILE_ARTBOARD_LIMIT
        }
        selected={
          fileInfo &&
          fileInfo.artboardsCount <= PRICING.SMALL_FILE_ARTBOARD_LIMIT
        }
        artboardsCount={fileInfo && fileInfo.artboardsCount}
      >
        <Trans
          i18nKey="section/pricing:plans.payAsYouGo.options.smallFile"
          values={{ artboardsLimit: PRICING.SMALL_FILE_ARTBOARD_LIMIT }}
          components={[
            <PricingTooltip text={t("tooltips.artboard")} key={0} />,
          ]}
        />
      </PricingListElement>

      <PricingListElement
        price={{
          value: PRICING.PAY_AS_YOU_GO.priceLargeFile,
          suffix: t("plans.payAsYouGo.file"),
        }}
        disabled={
          fileInfo &&
          fileInfo.artboardsCount <= PRICING.SMALL_FILE_ARTBOARD_LIMIT
        }
        selected={
          fileInfo &&
          fileInfo.artboardsCount > PRICING.SMALL_FILE_ARTBOARD_LIMIT
        }
        artboardsCount={fileInfo && fileInfo.artboardsCount}
      >
        <Trans
          i18nKey="section/pricing:plans.payAsYouGo.options.largeFile"
          components={[
            <PricingTooltip text={t("tooltips.artboard")} key={0} />,
          ]}
        />
      </PricingListElement>
    </PricingCard>
  );
};
