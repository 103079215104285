import React from "react";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";

import {
  PricingCard,
  PricingRadioElement,
  PricingTooltip,
} from "@/components/PricingCard";
import { PRICING } from "@/constants/pricing";
import { IPaymentType } from "@/containers/Converter/types";

interface ICreditsCard {
  cta: {
    title: string;
    onClick: (planId: IPaymentType) => void;
  };
}

export const CreditsCard: React.FC<ICreditsCard> = ({ cta }) => {
  const { t } = useTranslation("section/pricing");

  const [selectedPlan, setSelectedPlan] = React.useState<IPaymentType>(
    "CREDITS_2",
  );

  return (
    <PricingCard
      header={{
        backgroundType: "DARK_BLUE",
        title: t("plans.credits.title"),
        subtitle: t("plans.credits.subtitle"),
      }}
      cta={{
        ...cta,
        onClick: () => cta.onClick(selectedPlan),
      }}
    >
      {PRICING.CREDITS.map((plan, i) => (
        <PricingRadioElement
          key={`credits-${plan.credits}`}
          onSelect={() => setSelectedPlan(`CREDITS_${i + 1}` as IPaymentType)}
          selected={selectedPlan === `CREDITS_${i + 1}`}
          price={{ value: plan.price }}
          saving={plan.saving}
        >
          <Trans
            key={`credits-${plan.credits}-creditsAmount`}
            i18nKey="section/pricing:plans.credits.options.creditsAmount"
            values={{ amount: plan.credits }}
            components={[
              <PricingTooltip
                text={t("tooltips.credit", {
                  price: Math.round(plan.price / plan.credits),
                })}
                key={`credits-${plan.credits}-creditsAmount-tooltip`}
              />,
            ]}
          />
          <Trans
            key={`credits-${plan.credits}-unlimitedArtboards`}
            i18nKey="section/pricing:plans.credits.options.unlimitedArtboards"
            components={[
              <PricingTooltip
                text={t("tooltips.artboard")}
                key={`credits-${plan.credits}-unlimitedArtboards-tooltip`}
              />,
            ]}
          />
        </PricingRadioElement>
      ))}
    </PricingCard>
  );
};
