import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useAuth } from "@/hooks/useAuth";
import { IWithCustomStyle, useTheme } from "@/hooks/useTheme";
import { useRouter } from "next/router";

import { Button } from "@/components/Button";

interface IProps {
  onUserLogIn?: () => void;
}

export const AlreadyACustomer: React.FC<IProps & IWithCustomStyle> = props => {
  const { css } = useFela();
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { user, login } = useAuth();
  const { pathname } = useRouter();

  const { onUserLogIn, customStyle } = props;

  const clickHandler = () => {
    login(false).then(() => {
      if (onUserLogIn) onUserLogIn();
    });
  };

  return !user && pathname !== "/pricing" ? (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        ...customStyle,
      })}
    >
      <div
        className={css({
          display: "inline-flex",
          margin: "32px auto 0",
          padding: "26px 32px",
          background: colors.GHOST_WHITE,
          borderRadius: 16,
        })}
      >
        <p className={css({ fontWeight: "bold" })}>
          {t("converter:existingUser.alreadyCustomer")}
        </p>
        <Button
          customStyle={{
            height: "auto",
            width: "auto",
            marginLeft: 24,
            fontSize: 14,
            textTransform: "uppercase",
            color: colors.ORANGE,
            backgroundColor: "inherit",
            borderColor: colors.GHOST_WHITE,
            padding: 0,
          }}
          id="payment-login"
          onClick={clickHandler}
        >
          {t("converter:existingUser.login")}
        </Button>
      </div>
    </div>
  ) : null;
};
