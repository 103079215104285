import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";

import { useInquiryForm } from "@/hooks/useInquiryForm";
import {
  PricingCard,
  PricingListElement,
  PricingTooltip,
} from "@/components/PricingCard";
import { PRICING } from "@/constants/pricing";
import { IPaymentType } from "@/containers/Converter/types";
import { useTheme } from "@/hooks/useTheme";
import { ANALYTICS } from "@/constants/analytics";
import { FormSourceType } from "@/containers/InquiryForm";

interface ISubscriptionCard {
  cta: {
    title: string;
    onClick: (planId: IPaymentType) => void;
  };
}

export const SubscriptionCard: React.FC<ISubscriptionCard> = ({ cta }) => {
  const { t } = useTranslation("section/pricing");
  const { css } = useFela();
  const { colors } = useTheme();
  const { toggleInquiryForm } = useInquiryForm();

  return (
    <PricingCard
      header={{
        backgroundType: "RED",
        title: t("plans.subscription.title"),
        subtitle: t("plans.subscription.subtitle"),
      }}
      cta={{
        ...cta,
        onClick: () => {
          cta.onClick("SUBSCRIPTION_ENTERPRISE");
        },
      }}
    >
      <PricingListElement
        price={{
          value: PRICING.SUBSCRIPTION.price,
          suffix: t("plans.subscription.priceSuffix"),
        }}
      >
        <Trans
          key="sub-description-unlimitedFiles"
          i18nKey="section/pricing:plans.subscription.options.unlimitedFiles"
          components={[
            <PricingTooltip
              text={t("tooltips.unlimitedFiles")}
              key="sub-description-unlimitedFiles-tooltip"
            />,
          ]}
        />
        <Trans
          key="sub-description-automatedBulkConversion"
          i18nKey="section/pricing:plans.subscription.options.automatedBulkConversion"
          components={[
            <PricingTooltip
              text={t("tooltips.automatedBulkConversion")}
              key="sub-description-automatedBulkConversion-tooltip"
            />,
          ]}
        />
        <Trans
          key="sub-description-importFrom"
          i18nKey="section/pricing:plans.subscription.options.importFrom"
          components={[
            <PricingTooltip
              text={t("tooltips.importFrom")}
              key="sub-description-importFrom-tooltip"
            />,
          ]}
        />
        {t("section/pricing:plans.subscription.options.sso")}
        <Trans
          key="sub-description-support"
          i18nKey="section/pricing:plans.subscription.options.support"
        />
        <Trans
          key="sub-description-encryption"
          i18nKey="section/pricing:plans.subscription.options.encryption"
          components={[
            <PricingTooltip
              text={t("tooltips.encryption")}
              key="sub-description-encryption-tooltip"
            />,
          ]}
        />
      </PricingListElement>

      <div
        className={css({
          marginTop: 30,
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Trans
          i18nKey="section/pricing:plans.subscription.contactUs"
          components={[
            <span
              key="contact-us-text"
              className={css({ color: colors.DARK_GREY, fontWeight: 700 })}
            />,
            <button
              type="button"
              aria-label="Contact sales"
              key="contact-us-button"
              id={ANALYTICS.contactUsSubscriptionLinkId}
              onClick={() =>
                toggleInquiryForm(FormSourceType.ENTERPRISE_INQUIRY)
              }
              className={css({
                color: colors.RED,
                fontSize: 16,
                textDecoration: "none",
                backgroundColor: "transparent",
                border: "none",
                fontWeight: 700,
                outline: "none",
                nested: {
                  ":hover": {
                    cursor: "pointer",
                  },
                },
              })}
            />,
          ]}
        />
      </div>
    </PricingCard>
  );
};
